import {useCallback, useEffect, useState} from 'react'
import {Platform} from '../types/Platform'
import {MaspError, MaspErrorType} from '../errors/MaspError'
import {MobileAppDetail} from '../types/MobileAppDetail'
import {MobileAppStatusName} from '../types/MobileAppStatusName'
import {MobileAppStatusUI} from '../types/MobileAppStatusUI'
import useNetworkService from '../services/NetworkService.hooks'
import {MobileAppStatus} from '../types/MobileAppStatus'

export type AppDetailResult = {
  appDetail?: MobileAppDetail
  cancelAppSubmission: () => Promise<void>
  updateAppStatus: (
    status: MobileAppStatus,
    statusUI?: MobileAppStatusUI,
    isRip?: boolean,
    releaseDate?: Date
  ) => void
  isLoading: boolean
  error?: MaspError
}

export const useAppDetail = (id?: string, platform?: Platform): AppDetailResult => {
  const [appDetail, setAppDetail] = useState<MobileAppDetail | undefined>(undefined)
  const [error, setError] = useState<MaspError | undefined>(undefined)
  const {getMobileAppDetail, updateMobileApp, isLoading} = useNetworkService()

  const fetchAppDetail = useCallback(async () => {
    if (!id || !platform) {
      const appDetailMissingParamsError = new MaspError(MaspErrorType.AppDetailMissingParams)
      // eslint-disable-next-line no-console
      console.error(`🚨 ${appDetailMissingParamsError.message}`)
      setError(appDetailMissingParamsError)
      return
    }

    try {
      const mobileAppDetail = await getMobileAppDetail(platform, id)
      setAppDetail(mobileAppDetail)
    } catch (networkError: any) {
      setError(networkError)
    }
  }, [id, platform, getMobileAppDetail])

  const updateAppStatus = useCallback((
    status: MobileAppStatus,
    statusUI?: MobileAppStatusUI,
    isRip?: boolean,
    releaseDate?: Date,
  ) => {
    setAppDetail((prevAppDetail) => {
      if (!prevAppDetail) {
        return prevAppDetail
      }
      return {
        ...prevAppDetail,
        status,
        statusUI,
        isRip: (isRip !== undefined) ? isRip : prevAppDetail.isRip,
        lastReleaseDate: releaseDate || prevAppDetail.lastReleaseDate,
      }
    })
  }, [])

  const cancelAppSubmission = useCallback(async () => {
    if (!id || !platform) {
      const appDetailMissingParamsError = new MaspError(MaspErrorType.AppDetailMissingParams)
      // eslint-disable-next-line no-console
      console.error(`🚨 ${appDetailMissingParamsError.message}`)
      throw appDetailMissingParamsError
    }

    try {
      const updatedMobileApp = await updateMobileApp(id, platform, {
        status: {name: MobileAppStatusName.Cancelled},
      })
      updateAppStatus(updatedMobileApp.mobileApp.status)
    } catch (networkError: any) {
      throw networkError
    }
  }, [id, platform, updateAppStatus, updateMobileApp])

  useEffect(() => {
    fetchAppDetail()
  }, [fetchAppDetail])

  return {
    appDetail,
    cancelAppSubmission,
    updateAppStatus,
    isLoading,
    error,
  }
}
