import {useCallback, useState} from 'react'
import {BitriseBuildInfo, BitriseBuildState} from '../../types/BitriseBuildInfo'
import {Platform} from '../../types/Platform'
import useNetworkService from '../../services/NetworkService.hooks'
import {usePollingInterval} from '../../hooks/usePollingInterval'

export type AppRipBitriseSectionResult = {
    bitriseBuildInfo?: BitriseBuildInfo
    handleBitriseStartWorkflow: () => Promise<void>
    isLoading: boolean
    error?: any
}

export const useAppRipBitriseSection = (
  id: string,
  platform: Platform,
  initialBitriseBuildInfo?: BitriseBuildInfo,
): AppRipBitriseSectionResult => {
  const {getMobileAppDetail, startBitriseWorkflow, isLoading} = useNetworkService()
  const [error, setError] = useState(null)
  const [bitriseBuildInfo, setBitriseBuildInfo] = useState<BitriseBuildInfo | undefined>(
    initialBitriseBuildInfo,
  )

  const fetchAppDetail = useCallback(async () => {
    try {
      const mobileAppDetail = await getMobileAppDetail(platform, id)
      setBitriseBuildInfo(mobileAppDetail.bitriseBuildInfo)
    } catch (e: any) {
      setError(e)
    }
  }, [id, platform, getMobileAppDetail])

  const handleBitriseStartWorkflow = useCallback(async () => {
    try {
      const response = await startBitriseWorkflow(platform, id)
      setBitriseBuildInfo(response)
    } catch (e: any) {
      setError(e)
    }
  }, [id, platform, startBitriseWorkflow])

  usePollingInterval(
    bitriseBuildInfo?.state === BitriseBuildState.InProgress,
    fetchAppDetail,
    10000,
  )

  return {bitriseBuildInfo, handleBitriseStartWorkflow, isLoading, error}
}
