import React from 'react'
import {useParams} from 'react-router'
import {Platform} from '../types/Platform'
import {useAppDetail} from './AppDetail.hooks'
import {Spinner} from '../components/Spinner'
import {strings} from '../localization/strings'
import {Alert, Button, Flex, Layout, Modal, Tooltip, Typography, message} from 'antd'
import {styles} from './AppDetail.styles'
import {AppDetailHeader} from '../components/AppDetail/AppDetailHeader'
import AppGeneralInformationSection from '../components/AppDetail/AppGeneralInformationSection'
import AppLinksSection from '../components/AppDetail/AppLinksSection'
import AppScreenshotsSection from '../components/AppDetail/AppScreenshotsSection'
import {Content} from 'antd/es/layout/layout'
import Paragraph from 'antd/es/typography/Paragraph'
import {useMaspNavigate} from '../hooks/useMaspNavigate'
import AppRipSection from '../components/AppDetail/AppRipSection'
import {AppStatusChangeCallbackParams} from '../components/AppStatus/StatusSettings'
import {MobileAppStatusName} from '../types/MobileAppStatusName'
import {MobileAppStatusUI} from '../types/MobileAppStatusUI'
import {useUserInfo} from '../hooks/useUserInfo'
import {BitriseBuildState} from '../types/BitriseBuildInfo'
import {MobileAppStatus} from '../types/MobileAppStatus'

type AppDetailParams = {
  id?: string
  platform?: Platform
}

const AppDetail: React.FC = () => {
  const {id, platform} = useParams<AppDetailParams>()
  const {
    appDetail,
    cancelAppSubmission,
    updateAppStatus,
    isLoading,
    error,
  } = useAppDetail(id, platform)
  const {navigateSubmission, goBack} = useMaspNavigate()
  const {isAdmin} = useUserInfo()
  const {bitriseBuildInfo, status} = appDetail || {}
  const {state: buildState} = bitriseBuildInfo || {}
  const {appDetail: texts} = strings

  const isSubmissionEditable = isAdmin &&
  appDetail?.isRip &&
  status?.name !== MobileAppStatusName.StoreSubmission &&
  status?.name !== MobileAppStatusName.StoreReview &&
  status?.name !== MobileAppStatusName.PendingRelease &&
  status?.name !== MobileAppStatusName.MDMDeployment

  const isSubmissionCancellable = isAdmin &&
  appDetail?.isRip &&
  buildState !== BitriseBuildState.InProgress

  const handleNewReleaseClick = () => {
    navigateSubmission(id, platform)
  }

  const handleEditSubmissionClick = () => {
    navigateSubmission(id, platform, true)
  }

  const handleAppStatusChange: AppStatusChangeCallbackParams =
  (_id: string,
    newStatus: MobileAppStatus,
    newStatusUI?: MobileAppStatusUI,
    isRip?: boolean,
    releaseDate?: Date,
  ) => {
    updateAppStatus(newStatus, newStatusUI, isRip, releaseDate)
  }

  const handleCancelSubmissionClick = () => {
    const cancelModal = Modal.confirm({
      title: texts.cancelSubmissionAlertTitle,
      content: texts.cancelSubmissionAlertContent,
      onOk: async () => {
        try {
          await cancelAppSubmission()
          message.success(texts.submissionCancelled)
          goBack()
        } catch (e: any) {
          message.error(`${texts.submissionCancelledFailed}; ${e.message}`)
        } finally {
          cancelModal.destroy()
        }
      },
    })
  }

  if (isLoading && !appDetail) {
    return <Spinner tip={strings.loadingMessage.fetchingData} />
  }

  if (error) {
    return (
      <Alert
        message={texts.notFound}
        description={error.message}
        showIcon
        type="error"
      />
    )
  }

  return appDetail && (
    <Layout>
      <Content style={styles.container}>
        <Flex vertical>
          <Flex>
            <AppDetailHeader
              icon={appDetail.icon}
              name={appDetail.name}
              platform={appDetail.platform}
              version={appDetail.version}
              status={appDetail.status.name} />
            <Flex
              style={styles.buttonContainer}
            >
              {
                isSubmissionCancellable &&
                <Button
                  type="default"
                  size="large"
                  style={styles.releaseButton}
                  onClick={handleCancelSubmissionClick}>
                  {strings.buttonTitle.cancelSubmission}
                </Button>
              }
              {
                appDetail?.status.name === MobileAppStatusName.Released &&
                <Button
                  type="primary"
                  size="large"
                  style={styles.releaseButton}
                  onClick={handleNewReleaseClick}>
                  {strings.buttonTitle.newRelease}
                </Button>
              }
              {
                isSubmissionEditable &&
                <Tooltip title={
                  buildState === BitriseBuildState.InProgress
                    ? texts.editingDisabledBuildProgress
                    : ''
                }>
                  <Button
                    type="primary"
                    size="large"
                    style={styles.releaseButton}
                    onClick={handleEditSubmissionClick}
                    disabled={buildState === BitriseBuildState.InProgress}>
                    {strings.buttonTitle.editSubmission}
                  </Button>
                </Tooltip>
              }
            </Flex>
          </Flex>

          {appDetail.isRip &&
          <AppRipSection mobileApp={appDetail} onAppStatusChange={handleAppStatusChange} />
          }

          <AppGeneralInformationSection mobileApp={appDetail} />

          <Typography.Title level={4}>{texts.appDescriptionTitle}</Typography.Title>

          <Paragraph
            style={styles.description}
            ellipsis={{rows: 10, expandable: true, symbol: 'more'}}>
            {appDetail.description}
          </Paragraph>

          <AppLinksSection
            links={appDetail.links}
            id={appDetail.id}
            platform={appDetail.platform}
            distributionMethod={appDetail.distributionMethod}
            status={appDetail.status}
          />

          {
            appDetail.screenshots.length > 0 &&
            <AppScreenshotsSection screenshots={appDetail.screenshots} />
          }
        </Flex>
      </Content>
    </Layout>
  )
}

export default AppDetail
