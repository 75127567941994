import {
  exactDecoder,
  objectDecoder,
  oneOfDecoders,
  stringDecoder,
  undefinedDecoder,
} from 'json-decoder'
import {BitriseBuildStep, bitriseBuildStepDecoder} from './BitriseBuildStep'

export enum BitriseBuildState {
  Success = 'success',
  Failed = 'failed',
  InProgress = 'inProgress'
}

export const buildStateDecoder = oneOfDecoders(
  exactDecoder(BitriseBuildState.Success),
  exactDecoder(BitriseBuildState.Failed),
  exactDecoder(BitriseBuildState.InProgress),
)

export type BitriseBuildInfo = {
  state: BitriseBuildState
  url: string
  failedStep?: BitriseBuildStep
  errorMessage?: string
}

export const bitriseBuildInfoDecoder = objectDecoder<BitriseBuildInfo>({
  state: buildStateDecoder,
  url: stringDecoder,
  failedStep: oneOfDecoders(bitriseBuildStepDecoder, undefinedDecoder),
  errorMessage: oneOfDecoders(stringDecoder, undefinedDecoder),
})
