import React from 'react'
import {Col, Collapse, Flex, List, Row, Space, Typography} from 'antd'
import {StatusLabel} from '../AppStatus/StatusLabel'
import {Link} from 'react-router-dom'
import {MobileAppDetail} from '../../types/MobileAppDetail'
import {strings} from '../../localization/strings'
import StatusSettings, {AppStatusChangeCallbackParams} from '../AppStatus/StatusSettings'
import {useUserInfo} from '../../hooks/useUserInfo'
import AppRipBitriseSection from './AppRipBitriseSection'
import useAppRipStyles from './AppRipSection.styles'
import {CaretRightOutlined} from '@ant-design/icons'

const {Title, Text} = Typography

export type AppRipSectionProps = {
    mobileApp: MobileAppDetail
    onAppStatusChange: AppStatusChangeCallbackParams
}

const AppRipSection: React.FC<AppRipSectionProps> = ({
  mobileApp, onAppStatusChange,
}) => {
  const {isAdmin} = useUserInfo()
  const {rip: texts} = strings.appDetail
  const {
    id,
    platform,
    demo,
    statusUI,
    bitriseBuildInfo,
    jiraIssue: {url: jiraLink},
    links: {artifactoryRepository, ciCdPlatform},
  } = mobileApp
  const {styles} = useAppRipStyles()

  const getTestingDetails = () => (
    (
      <Flex
        vertical>
        <Title level={5} className={styles.testingSectionHeading}>{texts.testingDetails}</Title>
        <Collapse
          defaultActiveKey={['1']}
          className={styles.testingSectionContainer}
          bordered={false}
          size="small"
          expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          items={[{
            key: '1',
            label: <Text strong>{texts.demoInstructions}</Text>,
            style: {borderBottom: 'none'},
            children: (
              <Flex vertical gap="small">
                <Text>{demo.instructions}</Text>
                {isAdmin && demo.username &&
                  <Space>
                    <Text strong>{texts.username}</Text>
                    <Text>{demo.username}</Text>
                  </Space>
                }
                {isAdmin && demo.password &&
                  <Space>
                    <Text strong>{texts.password}</Text>
                    <Text>{demo.password}</Text>
                  </Space>
                }
              </Flex>
            ),
          }, {
            key: '2',
            label: <Text strong>{texts.appTesters}</Text>,
            children: (
              <>
                <List
                  className={styles.appTestersList}
                  size="small"
                  bordered={false}
                  dataSource={mobileApp.testerEmails}
                  renderItem={(item) =>
                    <List.Item style={{border: 'none'}}>{'\u25CF'} {item}</List.Item>
                  }
                />
              </>
            ),
          }]}
        />
      </Flex>
    )
  )

  const renderReleaseSection = () => (
    <Flex className={styles.releaseContainer} vertical gap="middle">
      <Row>
        <Col className={styles.columnTitle}>
          <Text strong>{texts.status}</Text>
        </Col>
        <Col>
          <Space>
            :
            <StatusLabel status={statusUI} />
            {
              isAdmin &&
              <StatusSettings
                appId={mobileApp.id}
                platform={mobileApp.platform}
                currentStatus={mobileApp.status.name}
                onAppStatusChange={onAppStatusChange} />
            }
          </Space>
        </Col>
      </Row>
      {jiraLink &&
        <Row>
          <Col className={styles.columnTitle}>
            <Text strong>{texts.jiraLink}</Text>
          </Col>
          <Col>
            <Space>
              :<Link to={jiraLink}>{jiraLink}</Link>
            </Space>
          </Col>
        </Row>
      }
      {getTestingDetails()}
    </Flex>
  )

  return (
    <Flex vertical gap="middle">
      <Title level={4}>{texts.title}</Title>
      <Flex>
        {renderReleaseSection()}
        {isAdmin && artifactoryRepository && ciCdPlatform &&
        <AppRipBitriseSection
          id={id}
          platform={platform}
          initialBitriseBuildInfo={bitriseBuildInfo} />
        }
      </Flex>
    </Flex>
  )
}

export default AppRipSection
