import React from 'react'
import {Button, Flex, Steps, Typography, message} from 'antd'
import {BitriseBuildInfo, BitriseBuildState} from '../../types/BitriseBuildInfo'
import {Platform} from '../../types/Platform'
import {
  BitriseBuildStep,
  getBitriseBuildStepName,
  getBitriseBuildStepPosition,
} from '../../types/BitriseBuildStep'
import {LinkOutlined, LoadingOutlined} from '@ant-design/icons'
import {strings} from '../../localization/strings'
import useAppRipBitriseStyles from './AppRipBitriseSection.styles'
import {useAppRipBitriseSection} from './AppRipBitriseSection.hooks'

export type AppRipBitriseSectionProps = {
  id: string,
  platform: Platform,
  initialBitriseBuildInfo?: BitriseBuildInfo
}

const AppRipBitriseSection: React.FC<AppRipBitriseSectionProps> = ({
  id,
  platform,
  initialBitriseBuildInfo,
}) => {
  const {rip: texts} = strings.appDetail
  const {styles} = useAppRipBitriseStyles()
  const {
    bitriseBuildInfo,
    handleBitriseStartWorkflow,
    isLoading,
    error,
  } = useAppRipBitriseSection(id, platform, initialBitriseBuildInfo)

  const getCurrentStepPosition = (): number => {
    if (!bitriseBuildInfo) {
      return 0
    }
    return bitriseBuildInfo.state === BitriseBuildState.Failed && bitriseBuildInfo.failedStep
      ? getBitriseBuildStepPosition(bitriseBuildInfo.failedStep)
      : 3
  }

  const getIcon = () => {
    if (bitriseBuildInfo && bitriseBuildInfo.state === BitriseBuildState.InProgress) {
      return <LoadingOutlined />
    }
    return null
  }

  if (error) {
    message.error(error.message)
  }

  return (
    <Flex
      vertical
      className={styles.container}
    >

      <Typography.Title
        level={5}
        className={styles.heading}
      >
        {texts.bitriseWorkflow}
      </Typography.Title>

      {bitriseBuildInfo &&
      <Steps
        direction="vertical"
        size="small"
        current={getCurrentStepPosition()}
        status={bitriseBuildInfo.state === BitriseBuildState.Failed ? 'error' : undefined}
        iconPrefix="bitrise"
        items={[
          {
            title: getBitriseBuildStepName(BitriseBuildStep.AppResign),
            icon: getIcon(),
          },
          {
            title: getBitriseBuildStepName(BitriseBuildStep.VulnerabilityScanUpload),
            icon: getIcon(),
          },
          {
            title: getBitriseBuildStepName(BitriseBuildStep.StoreUpload, platform),
            icon: getIcon()},
        ]}
      />
      }

      {!bitriseBuildInfo &&
        <Button
          type="primary"
          className={styles.bitriseButton}
          onClick={handleBitriseStartWorkflow}
          loading={isLoading}
        >
          {strings.buttonTitle.startBitrise}
        </Button>
      }

      {bitriseBuildInfo?.state === BitriseBuildState.Failed && bitriseBuildInfo?.errorMessage && (
        <Typography.Text
          className={styles.errorMessageText}
          type="danger"
        >
          <span>
            {strings.error.title}: {bitriseBuildInfo.errorMessage}
            <Button
              className={styles.retryButton}
              type="link"
              loading={isLoading}
              onClick={handleBitriseStartWorkflow}
            >
              {strings.buttonTitle.retry}.
            </Button>
          </span>
        </Typography.Text>
      )}

      {bitriseBuildInfo && (
        <Button
          className={styles.viewBuildButton}
          type="default"
          href={bitriseBuildInfo.url}
          target="_blank"
          icon={<LinkOutlined />}
        >
          {strings.buttonTitle.viewBuildDetails}
        </Button>
      )}
    </Flex>
  )
}

export default AppRipBitriseSection

AppRipBitriseSection.defaultProps = {
  initialBitriseBuildInfo: undefined,
}
