import {useCallback, useEffect, useState} from 'react'
import {MobileAppListItem} from '../types/MobileAppListItem'
import useNetworkService from '../services/NetworkService.hooks'
import {MobileAppStatusName} from '../types/MobileAppStatusName'
import {Platform} from '../types/Platform'
import {AppStatusChangeCallbackParams} from '../components/AppStatus/StatusSettings'
import {MobileAppStatusUI} from '../types/MobileAppStatusUI'
import {MobileAppStatus} from '../types/MobileAppStatus'

type HomeResult = {
    isLoading: boolean
    filteredAndroidApps: MobileAppListItem[]
    filteredIosApps: MobileAppListItem[]
    filteredRips: MobileAppListItem[]
    handleAppStatusChange: AppStatusChangeCallbackParams
    rips: MobileAppListItem[]
    searchQuery: string
    setSearchQuery: (query: string) => void
}

export const useHome = (): HomeResult => {
  const [rips, setRips] = useState<MobileAppListItem[]>([])
  const [filteredRips, setFilteredRips] = useState<MobileAppListItem[]>([])
  const [iosApps, setIosApps] = useState<MobileAppListItem[]>([])
  const [filteredIosApps, setFilteredIosApps] = useState<MobileAppListItem[]>([])
  const [androidApps, setAndroidApps] = useState<MobileAppListItem[]>([])
  const [filteredAndroidApps, setFilteredAndroidApps] = useState<MobileAppListItem[]>([])
  const [searchQuery, setSearchQuery] = useState('')

  const {getMobileApps, isLoading} = useNetworkService()

  const fetchMobileApps = useCallback(async () => {
    try {
      const [
        fetchedRips,
        fetchedIosApps,
        fetchedAndroidApps,
      ] = await Promise.all([
        getMobileApps('rip'),
        getMobileApps('ios'),
        getMobileApps('android'),
      ])
      setRips(fetchedRips)
      setIosApps(fetchedIosApps)
      setAndroidApps(fetchedAndroidApps)
    } catch (error) {
      // TO-DO:- Error Handling to be done
      // eslint-disable-next-line no-console
      console.log(`${error}`)
    }
  }, [getMobileApps])

  useEffect(() => {
    fetchMobileApps()
  }, [fetchMobileApps])

  useEffect(() => {
    setFilteredRips(rips.filter((app) =>
      app.name.toLowerCase().includes(searchQuery),
    ))
    setFilteredIosApps(iosApps.filter((app) =>
      app.name.toLowerCase().includes(searchQuery),
    ))
    setFilteredAndroidApps(androidApps.filter((app) =>
      app.name.toLowerCase().includes(searchQuery),
    ))
  }, [androidApps, iosApps, rips, searchQuery])

  const handleAppStatusChange: AppStatusChangeCallbackParams =
  useCallback((id: string, status: MobileAppStatus, statusUI?: MobileAppStatusUI) => {
    const index = rips.findIndex((item) => item.id === id)
    if (index === -1) {
      return
    }
    const updatedMobileApp = {...rips[index], status, statusUI}
    if (
      status.name === MobileAppStatusName.Released ||
      status.name === MobileAppStatusName.Cancelled
    ) {
      const updatedRips = rips.filter((item) => item.id !== updatedMobileApp.id)
      setRips(updatedRips)
      if (status.name === MobileAppStatusName.Released) {
        if (updatedMobileApp.platform === Platform.Ios) {
          setIosApps([...iosApps, updatedMobileApp])
        } else {
          setAndroidApps([...androidApps, updatedMobileApp])
        }
      }
      return
    }
    const updatedRips = [...rips]
    updatedRips[index] = updatedMobileApp
    setRips(updatedRips)
  }, [androidApps, iosApps, rips])

  return {
    isLoading,
    filteredAndroidApps,
    filteredIosApps,
    filteredRips,
    handleAppStatusChange,
    rips,
    searchQuery,
    setSearchQuery,
  }
}
