import {objectDecoder} from 'json-decoder'
import {MobileAppStatusName, mobileAppStatusNameDecoder} from './MobileAppStatusName'

export type MobileAppStatus = {
  name: MobileAppStatusName
}

export const mobileAppStatusDecoder = objectDecoder<MobileAppStatus>({
  name: mobileAppStatusNameDecoder,
})
