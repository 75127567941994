import {oneOfDecoders, exactDecoder} from 'json-decoder'
import {Platform} from './Platform'
import {strings} from '../localization/strings'

export enum BitriseBuildStep {
    AppResign = 'app-resign',
    VulnerabilityScanUpload = 'vulnerability-scan-upload',
    StoreUpload = 'store-upload',
}

export const bitriseBuildStepDecoder = oneOfDecoders(
  exactDecoder(BitriseBuildStep.AppResign),
  exactDecoder(BitriseBuildStep.VulnerabilityScanUpload),
  exactDecoder(BitriseBuildStep.StoreUpload),
)

export const getBitriseBuildStepName = (step: BitriseBuildStep, platform?: Platform): string => {
  const {rip: texts} = strings.appDetail
  switch (step) {
    case BitriseBuildStep.AppResign:
      return texts.appResign
    case BitriseBuildStep.VulnerabilityScanUpload:
      return texts.vulnerabilityScanUpload
    case BitriseBuildStep.StoreUpload:
      return platform === Platform.Ios ? texts.testFlightUpload : texts.googlePlayUpload
    default:
      return ''
  }
}

export const getBitriseBuildStepPosition = (step: BitriseBuildStep): number =>
  Object.values(BitriseBuildStep).indexOf(step)
