import {createStyles} from 'antd-style'

const useAppRipStyles = createStyles(() => ({
  testingSectionHeading: {
    marginTop: 10,
  },
  testingSectionContainer: {
    marginRight: 10,
    backgroundColor: 'transparent',
  },
  releaseContainer: {
    width: '100%',
  },
  columnTitle: {
    width: '70px',
  },
  appTestersList: {
    width: 'fit-content',
  },
}))

export default useAppRipStyles
