import {useCallback, useRef, useState} from 'react'
import {NetworkService} from './NetworkService'
import {MobileAppDetail} from '../types/MobileAppDetail'
import {config} from '../config/config'
import {Platform} from '../types/Platform'
import {SubmissionResponse} from '../types/SubmissionResponse'
import {AppUpdateResponse} from '../types/AppUpdateResponse'
import {GetMobileAppsFilter} from '../types/GetMobileAppsFilter'
import {MobileAppListItem} from '../types/MobileAppListItem'
import {BitriseBuildInfo} from '../types/BitriseBuildInfo'

type NetworkServiceResult = {
  getMobileApps: (filter?: GetMobileAppsFilter) => Promise<MobileAppListItem[]>
  getMobileAppDetail: (platform: Platform, id: string) => Promise<MobileAppDetail>
  startBitriseWorkflow: (platform: Platform, id: string) => Promise<BitriseBuildInfo>
  submitMobileApp: (mobileApp: MobileAppDetail) => Promise<SubmissionResponse>
  updateMobileApp: (
    id: string,
    platform: Platform,
    mobileApp: Partial<MobileAppDetail>
  ) => Promise<AppUpdateResponse>
  isLoading: boolean
}

const useNetworkService = () : NetworkServiceResult => {
  const {current: networkService} = useRef(new NetworkService(config.backendBaseUrl, config.msal))
  const [isLoading, setIsLoading] = useState(false)

  const updateMobileApp = useCallback(async (
    id: string,
    platform: Platform,
    mobileApp: Partial<MobileAppDetail>,
  ) => {
    setIsLoading(true)
    try {
      const response = await networkService.updateMobileApp(
        id,
        platform,
        mobileApp,
      )
      return response
    } catch (e: any) {
      throw e
    } finally {
      setIsLoading(false)
    }
  }, [networkService])

  const getMobileAppDetail = useCallback(async (
    platform: Platform,
    id: string,
  ) => {
    setIsLoading(true)
    try {
      const response = await networkService.getMobileAppDetail(
        platform,
        id,
      )
      return response
    } catch (e: any) {
      throw e
    } finally {
      setIsLoading(false)
    }
  }, [networkService])

  const submitMobileApp = useCallback(async (
    mobileApp: MobileAppDetail,
  ) => {
    setIsLoading(true)
    try {
      const response = await networkService.submitMobileApp(
        mobileApp,
      )
      return response
    } catch (e: any) {
      throw e
    } finally {
      setIsLoading(false)
    }
  }, [networkService])

  const getMobileApps = useCallback(async (
    filter?: GetMobileAppsFilter,
  ) => {
    setIsLoading(true)
    try {
      const response = await networkService.getMobileApps(
        filter,
      )
      return response
    } catch (e: any) {
      throw e
    } finally {
      setIsLoading(false)
    }
  }, [networkService])

  const startBitriseWorkflow = useCallback(async (
    platform: Platform,
    id: string,
  ) => {
    setIsLoading(true)
    try {
      const response = await networkService.startBitriseWorkflow(
        platform,
        id,
      )
      return response
    } catch (e: any) {
      throw e
    } finally {
      setIsLoading(false)
    }
  }, [networkService])

  return {
    getMobileApps,
    getMobileAppDetail,
    startBitriseWorkflow,
    submitMobileApp,
    updateMobileApp,
    isLoading,
  }
}

export default useNetworkService
