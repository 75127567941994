import {createStyles} from 'antd-style'

const useAppRipBitriseStyles = createStyles(() => ({
  container: {
    width: '25%',
    border: '1px solid #d9d9d9',
    borderRadius: '8px',
    paddingBottom: '16px',
    paddingRight: '16px',
    paddingLeft: '16px',
    background: '#f0f2f5',
    height: 'fit-content',
  },
  heading: {
    paddingBottom: '10px',
  },
  bitriseButton: {
    width: 'fit-content',
    borderRadius: 8,
  },
  errorMessageText: {
    marginTop: 0,
    fontSize: 13,
  },
  viewBuildButton: {
    borderRadius: 8,
  },
  retryButton: {
    padding: '0px',
    margin: 0,
    marginLeft: '5px',
    height: 'auto',
    lineHeight: '1',
    fontSize: 13,
  },
}))

export default useAppRipBitriseStyles
